<div class="menu-area">
    <div class="container hidden-xs">
        <div class="icon-container">
            <a [routerLink]="'/'">
                <img width="100" height="42" class="logo" src="https://static.next.dk/website/images/logos/next_logo_white.svg" />
            </a>
        </div>
        <nav id="primary_nav_wrap">
            <ul>
                <li>
                    <a [routerLink]="navigationService.getUrl('newcustomer')">{{ 'SHARED.MENU.NEW_CUSTOMER' | translate }}</a>
                </li>
                <li>
                    <a>{{ 'SHARED.MENU.INSURANCES' | translate }}</a>
                    <ul>
                        <li>
                            <a [routerLink]="navigationService.getUrl('forsikringer')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{
                                'SHARED.MENU.INSURANCE_OVERVIEW' | translate
                            }}</a>
                        </li>
                        <li>
                            <a [routerLink]="navigationService.getUrl('product-car')" routerLinkActive="active">{{ 'INSURANCE.CAR.PRODUCT' | translate }}</a>
                        </li>

                        <li>
                            <a [routerLink]="navigationService.getUrl('product-house')" routerLinkActive="active">{{ 'INSURANCE.HOUSE.PRODUCT' | translate }}</a>
                        </li>

                        <li>
                            <a [routerLink]="navigationService.getUrl('product-home')" routerLinkActive="active">{{ 'INSURANCE.HOME.PRODUCT' | translate }}</a>
                        </li>

                        <li>
                            <a [routerLink]="navigationService.getUrl('product-accident')" routerLinkActive="active">{{ 'INSURANCE.ACCIDENT.PRODUCT' | translate }}</a>
                        </li>

                        <li>
                            <a [routerLink]="navigationService.getUrl('product-dog')" routerLinkActive="active">{{ 'INSURANCE.DOG.PRODUCT' | translate }}</a>
                        </li>

                        <li>
                            <a [routerLink]="navigationService.getUrl('product-caravan')" routerLinkActive="active">{{ 'INSURANCE.CARAVAN.PRODUCT' | translate }}</a>
                        </li>

                        <li>
                            <a [routerLink]="navigationService.getUrl('product-secondhouse')" routerLinkActive="active">{{ 'INSURANCE.SECONDHOUSE.PRODUCT' | translate }}</a>
                        </li>

                        <li>
                            <a [routerLink]="navigationService.getUrl('product-moped')" routerLinkActive="active">{{ 'INSURANCE.MOPED.PRODUCT' | translate }}</a>
                        </li>

                        <li>
                            <a class="border-radius" [routerLink]="navigationService.getUrl('terms')" routerLinkActive="active">{{ 'SHARED.MENU.TERMS' | translate }}</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a [routerLink]="navigationService.getUrl('reportdamage')" routerLinkActive="active">{{ 'SHARED.MENU.REPORT_DAMAGE' | translate }}</a>
                </li>
                <li>
                    <a>{{ 'SHARED.MENU.ABOUT' | translate }}</a>
                    <ul>
                        <li>
                            <a [routerLink]="navigationService.getUrl('aboutnext')" routerLinkActive="active">{{ 'SHARED.MENU.ABOUT' | translate }}</a>
                        </li>

                        <li *ngIf="!displayPhoneNumber">
                            <a [routerLink]="navigationService.getUrl('onlineinsurance')" routerLinkActive="active">{{ 'SHARED.MENU.ONLINE_INSURANCE' | translate }}</a>
                        </li>

                        <li>
                            <a [routerLink]="navigationService.getUrl('press')" routerLinkActive="active">{{ 'SHARED.MENU.PRESS' | translate }}</a>
                        </li>

                        <li>
                            <a [routerLink]="navigationService.getUrl('whypeoplechange')" routerLinkActive="active">{{ 'SHARED.MENU.WHY_PEOPLE_CHANGE' | translate }}</a>
                        </li>

                        <li>
                            <a [routerLink]="navigationService.getUrl('jobinnext')" routerLinkActive="active">{{ 'SHARED.MENU.JOB' | translate }}</a>
                        </li>

                        <li>
                            <a [routerLink]="navigationService.getUrl('agency')" routerLinkActive="active">{{ 'SHARED.MENU.AGENCY' | translate }}</a>
                        </li>
                        <li *ngIf="showEmployeePage">
                            <a class="border-radius" [routerLink]="navigationService.getUrl('employees')" routerLinkActive="active">{{ 'SHARED.MENU.EMPLOYEES' | translate }}</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a [routerLink]="navigationService.getUrl('answer-question')" routerLinkActive="active">{{ 'SHARED.MENU.FAQ' | translate }}</a>
                </li>
                <li>
                    <a [routerLink]="navigationService.getUrl('contact')" routerLinkActive="active">{{ 'SHARED.MENU.CONTACT' | translate }}</a>
                </li>
            </ul>
        </nav>
    </div>
</div>
<div class="menu-area">
    <div class="left-menu-container visible-xs" [class.show]="showLeftMenu">
        <div class="background" (click)="toggleLeftMenu()"></div>
        <div class="left-menu">
            <a *ngIf="showLeftMenu" [routerLink]="'/'" class="logo">
                <i class="nf nf-next-logo"></i>
            </a>
            <a class="back-btn" (click)="toggleLeftMenu()">
                <i class="nf nf-chevron-left-icon-v3"></i>
            </a>
            <nav id="primary_nav" class="{{ selfcaremode ? 'selfcare' : '' }}">
                <ul>
                    <li>
                        <div *ngIf="isAuthenticated" class="header-item top-padding">
                            <a [routerLink]="navigationService.getUrl('selfcare-overview')">
                                <span>{{ userDisplayName }}</span>
                            </a>
                        </div>
                    </li>
                    <li *ngIf="isAuthenticated" class="break-line">
                        <hr />
                    </li>
                    <li class="basket-login-wrap">
                        <div class="header-item">
                            <div *ngIf="goToSummaryLoading" class="circle-loader small"></div>
                            <a *ngIf="!goToSummaryLoading" (click)="goToSummary()" id="cartlink" [title]="'SHARED.MENU.BASKET' | translate">
                                <i class="nf nf-basket-icon"></i>
                                <span class="badge" id="shoppingcart-status">{{ numInBasket }}</span>
                            </a>
                        </div>
                        <div class="break-line">
                            <hr />
                        </div>
                        <div *ngIf="!selfcaremode">
                            <div *ngIf="!isAuthenticated" class="btn btn-success header-item login-button">
                                <a class="login" [routerLink]="navigationService.getUrl('selfcare-login')">
                                    <span>
                                        {{ 'SHARED.MENU.LOG_IN' | translate }}
                                        <i class="nf nf-lock-icon-v3"></i>
                                    </span>
                                </a>
                            </div>
                            <div *ngIf="isAuthenticated" class="btn btn-success header-item login-button">
                                <a class="login" [routerLink]="navigationService.getUrl('selfcare-signout')">
                                    <span>
                                        {{ 'SHARED.MENU.LOG_OUT' | translate }}
                                        <i class="nf nf-lock-icon-v3"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </li>
                    <li class="break-line">
                        <hr />
                    </li>
                    <li>
                        <a [routerLink]="navigationService.getUrl('newcustomer')">{{ 'SHARED.MENU.NEW_CUSTOMER' | translate }}</a>
                    </li>
                    <li>
                        <a (click)="toggleShowProducts()" id="insurances-button"
                            >{{ 'SHARED.MENU.INSURANCES' | translate }}
                            <i class="arrowicon" [ngClass]="{ 'nf-chevron-down-icon-v3': showProducts, 'nf-chevron-right-icon-v3': !showProducts }"></i
                        ></a>
                        <ul *ngIf="showProducts">
                            <li>
                                <a [routerLink]="navigationService.getUrl('forsikringer')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{
                                    'SHARED.MENU.INSURANCE_OVERVIEW' | translate
                                }}</a>
                            </li>
                            <li>
                                <a id="car-insurance-anchor" [routerLink]="navigationService.getUrl('product-car')" routerLinkActive="active">{{ 'INSURANCE.CAR.PRODUCT' | translate }}</a>
                            </li>
                            <li>
                                <a id="house-insurance-anchor" [routerLink]="navigationService.getUrl('product-house')" routerLinkActive="active">{{
                                    'INSURANCE.HOUSE.PRODUCT' | translate
                                }}</a>
                            </li>
                            <li>
                                <a id="home-insurance-anchor" [routerLink]="navigationService.getUrl('product-home')" routerLinkActive="active">{{ 'INSURANCE.HOME.PRODUCT' | translate }}</a>
                            </li>
                            <li>
                                <a id="accident-insurance-anchor" [routerLink]="navigationService.getUrl('product-accident')" routerLinkActive="active">{{
                                    'INSURANCE.ACCIDENT.PRODUCT' | translate
                                }}</a>
                            </li>
                            <li>
                                <a id="dog-insurance-anchor" [routerLink]="navigationService.getUrl('product-dog')" routerLinkActive="active">{{ 'INSURANCE.DOG.PRODUCT' | translate }}</a>
                            </li>
                            <li>
                                <a id="caravan-insurance-anchor" [routerLink]="navigationService.getUrl('product-caravan')" routerLinkActive="active">{{
                                    'INSURANCE.CARAVAN.PRODUCT' | translate
                                }}</a>
                            </li>
                            <li>
                                <a id="second-house-insurance-anchor" [routerLink]="navigationService.getUrl('product-secondhouse')" routerLinkActive="active">{{
                                    'INSURANCE.SECONDHOUSE.PRODUCT' | translate
                                }}</a>
                            </li>
                            <li>
                                <a id="moped-insurance-anchor" [routerLink]="navigationService.getUrl('product-moped')" routerLinkActive="active">{{
                                    'INSURANCE.MOPED.PRODUCT' | translate
                                }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('terms')" routerLinkActive="active">{{ 'SHARED.MENU.TERMS' | translate }}</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a [routerLink]="navigationService.getUrl('reportdamage')" routerLinkActive="active">{{ 'SHARED.MENU.REPORT_DAMAGE' | translate }}</a>
                    </li>
                    <li>
                        <a (click)="toggleAboutNext()"
                            >{{ 'SHARED.MENU.ABOUT' | translate }}
                            <i class="arrowicon" [ngClass]="{ 'nf-chevron-down-icon-v3': showAboutNext, 'nf-chevron-right-icon-v3': !showAboutNext }"></i
                        ></a>
                        <ul *ngIf="showAboutNext">
                            <li>
                                <a [routerLink]="navigationService.getUrl('aboutnext')" routerLinkActive="active">{{ 'SHARED.MENU.ABOUT' | translate }}</a>
                            </li>
                            <li *ngIf="!displayPhoneNumber">
                                <a [routerLink]="navigationService.getUrl('onlineinsurance')" routerLinkActive="active">{{ 'SHARED.MENU.ONLINE_INSURANCE' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('press')" routerLinkActive="active">{{ 'SHARED.MENU.PRESS' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('whypeoplechange')" routerLinkActive="active">{{ 'SHARED.MENU.WHY_PEOPLE_CHANGE' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('jobinnext')" routerLinkActive="active">{{ 'SHARED.MENU.JOB' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('agency')" routerLinkActive="active">{{ 'SHARED.MENU.AGENCY' | translate }}</a>
                            </li>
                            <li>
                                <a [routerLink]="navigationService.getUrl('employees')" routerLinkActive="active">{{ 'SHARED.MENU.EMPLOYEES' | translate }}</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a [routerLink]="navigationService.getUrl('answer-question')" routerLinkActive="active">{{ 'SHARED.MENU.FAQ' | translate }}</a>
                    </li>
                    <li>
                        <a [routerLink]="navigationService.getUrl('contact')" routerLinkActive="active">{{ 'SHARED.MENU.CONTACT' | translate }}</a>
                    </li>
                    <li class="break-line">
                        <hr />
                    </li>
                    <li *ngIf="!contactRequestDefaultDisabled">
                        <div (click)="openContactRequestModal()" class="btn btn-success header-item button-spacing">
                            <span>{{ 'SHARED.TOPBAR.CALLBACK' | translate }}</span>
                            <i class="nf nf-phone-icon space-left"></i>
                        </div>
                    </li>
                    <li *ngIf="ischatopen">
                        <div (click)="openChat()" class="btn btn-success header-item button-spacing">
                            <span>{{ 'SHARED.TOPBAR.START_CHAT' | translate }}</span>
                            <i class="nf nf-chat-icon-v3 space-left"></i>
                        </div>
                    </li>
                    <li *ngIf="!ischatopen">
                        <div [routerLink]="navigationService.getUrl('contact')" class="btn btn-success header-item button-spacing">
                            <span>{{ 'SHARED.TOPBAR.CONTACT_US' | translate }}</span>
                            <i class="nf nf-chat-icon-v3 space-left"></i>
                        </div>
                    </li>
                    <li>
                        <div class="language-container">
                            <language-selector></language-selector>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    </div>

    <div class="container-fluid visible-xs" id="topbar">
        <div class="row">
            <div id="menu-topbar">
                <button type="button" class="burger-menu pull-left" (click)="toggleLeftMenu()">
                    <i class="nf nf-burger-icon-v3"></i>
                </button>

                <a *ngIf="!showLeftMenu" [routerLink]="'/'" id="next-logo">
                    <i class="nf nf-next-logo"></i>
                </a>

                <div class="phone-icon hand" *ngIf="!contactRequestDefaultDisabled || displayPhoneNumber" (click)="openContactRequestModal()">
                    <i class="nf nf-phone-icon"></i>
                </div>
            </div>
        </div>
    </div>
</div>
