<div id="employees">
    <div class="full-width-image-section newcustomer-top">
        <div class="arrow-up">
            <next-topbar></next-topbar>
            <next-menu></next-menu>

            <div class="container white">
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <h1>{{ 'PAGE.EMPLOYEES.TITLE' | translate }}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container bottomspace">
        <div *ngIf="firstDepartment">
            <div class="row">
                <div class="col-sm-12">
                    <div class="departmentname">{{ firstDepartment.name }}</div>
                </div>
            </div>

            <div class="row department-wrapper">
                <div *ngFor="let emp of firstDepartment.employees" class="col-md-3 col-sm-4 col-xs-6">
                    <employeebox [employee]="emp"></employeebox>
                </div>
            </div>
        </div>

        <div class="row trustpilot-container">
            <div class="col-sm-4">
                <a target="_blank" href="https://www.trustpilot.com/reviews/562a1e2d0000ff00091388af">
                    <img
                        class="trustpilot-stars"
                        src="https://static.next.dk/website/images/pages/employees/trustpilot-5.png"
                        srcset="https://static.next.dk/website/images/pages/employees/trustpilot-5@2x.png 2x"
                    />
                    <p class="trustpilot-name">Frederik Larsen</p>
                    <p class="trustpilot-text">
                        Deres kundeservice er hurtige og korrekte og det hele er simpelthen så nemt...<br />
                        <br />
                        En særlig tak til "<b>Alex</b>" fra kundeservice! Skarp fyr! :)
                    </p>
                </a>
            </div>
            <div class="col-sm-4">
                <a target="_blank" href="https://www.trustpilot.com/reviews/58807eb30dc2f6030caba9dc">
                    <img
                        class="trustpilot-stars"
                        src="https://static.next.dk/website/images/pages/employees/trustpilot-5.png"
                        srcset="https://static.next.dk/website/images/pages/employees/trustpilot-5@2x.png 2x"
                    />
                    <p class="trustpilot-name">Bonnie Hovmand Andreassen</p>
                    <p class="trustpilot-text">
                        Stor ros til <b>Julie</b> i chatten!<br />
                        Meget kompetent og helt konkurrencedygtige forsikringspriser
                    </p>
                </a>
            </div>
            <div class="col-sm-4">
                <a target="_blank" href="https://www.trustpilot.com/reviews/5bc057669d378009bc800274">
                    <img
                        class="trustpilot-stars"
                        src="https://static.next.dk/website/images/pages/employees/trustpilot-5.png"
                        srcset="https://static.next.dk/website/images/pages/employees/trustpilot-5@2x.png 2x"
                    />
                    <p class="trustpilot-name">Per B</p>
                    <p class="trustpilot-text">Havde en fanrastisk oplevelse. <b>Mike</b> oprettede min bilforsikring på 5 minutter, så jeg bare kunne sætte mig ud og køre. Tusind tak.</p>
                </a>
            </div>
        </div>

        <div *ngFor="let dep of otherDepartments">
            <div class="row">
                <div class="col-sm-12">
                    <hr class="seperator" />
                    <div class="departmentname">{{ dep.name }}</div>
                </div>
            </div>

            <div class="row department-wrapper">
                <div *ngFor="let emp of dep.employees" class="col-md-3 col-sm-4 col-xs-6">
                    <employeebox [employee]="emp"></employeebox>
                </div>
            </div>
        </div>
    </div>

    <next-footer></next-footer>
</div>
