import { Component, ViewEncapsulation, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { ContexService } from 'app/utils/contex/contex.service';
import { MetaTagsHelper } from 'app/utils/metaTags/metaTagsHelper';
import { Subscription } from 'rxjs';
import { DepartmentDetails } from 'app/utils/contex/models/departmentdetails';
import { TranslationService } from 'app/utils/translation/translation.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    moduleId: module.id,
    selector: 'employees',
    templateUrl: './employees.component.html',
    styleUrls: ['./employees.component.less'],
    encapsulation: ViewEncapsulation.None, // allows us to style contex content
})
export class EmployeesComponent implements OnInit, OnDestroy {
    bsModalRef: BsModalRef;
    subscriptions: Subscription[] = [];
    firstDepartment: DepartmentDetails;
    otherDepartments: DepartmentDetails[];

    constructor(
        public navigationService: NavigationService,
        public contexService: ContexService,
        private metaTagsHelper: MetaTagsHelper,
        private modalService: BsModalService,
        private translationService: TranslationService
    ) {
        this.metaTagsHelper.setTitleAndMetaTags('META.EMPLOYEES');
    }

    ngOnInit(): void {
        this.updateDepartments();

        this.translationService.getLanguageChangeEmitter().subscribe(() => {
            this.updateDepartments();
        });
    }

    updateDepartments() {
        this.contexService.getDepartments().subscribe((x) => {
            if (x != null) {
                this.firstDepartment = x[0];
                this.otherDepartments = x.slice(1);
            }
        });
    }

    openModal(template: TemplateRef<any>) {
        this.bsModalRef = this.modalService.show(template);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
