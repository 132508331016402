import { Component, OnInit, Input } from '@angular/core';
import { EmployeeDetails } from 'app/utils/contex/models/employeedetails';

@Component({
    selector: 'employeebox',
    templateUrl: './employeebox.component.html',
    styleUrls: ['./employeebox.component.less'],
})
export class EmployeeboxComponent implements OnInit {
    @Input() employee: EmployeeDetails;

    constructor() {}

    ngOnInit() {}
}
