import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from 'app/utils/navigation/navigation.service';
import { AuthService } from 'app/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TopbarService } from 'app/shared/topbar/topbar.service';
import { SiteSettingsService } from 'app/utils/sitesettings/site-settings.service';
import { ChatService } from 'app/utils/chat/chat.service';
import { SiteSettingType } from 'next-typescript-api';
import { AppConfigService } from 'app/utils/appconfig/app-config.service';

declare let $zopim: any;
@Component({
    moduleId: module.id,
    selector: 'next-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.less'],
})
export class MenuComponent implements OnInit, OnDestroy {
    showLeftMenu = false;
    showSummaryBasket: boolean;
    userDisplayName: string;
    isAuthenticated: boolean;
    numInBasket?: number;
    goToSummaryLoading: boolean;

    showProducts = false;
    showAboutNext = false;

    displayPhoneNumber: boolean;
    contactRequestDefaultDisabled: boolean;
    ischatopen: boolean;
    showEmployeePage: boolean;

    @Input() selfcaremodeoverride: boolean;
    public selfcaremode: boolean;
    subscriptions: Subscription[] = [];

    constructor(
        public navigationService: NavigationService,
        public auth: AuthService,
        public chatService: ChatService,
        private router: Router,
        private topbarService: TopbarService,
        private route: ActivatedRoute,
        private siteSettingsService: SiteSettingsService,
        private config: AppConfigService
    ) {
        this.selfcaremode = this.route.snapshot['_routerState'].url.indexOf('selvbetjening') >= 0;
    }

    ngOnInit(): void {
        this.showEmployeePage = this.config.appConfig.enableEmployeePage;

        this.siteSettingsService.getBooleanSetting(SiteSettingType.DisplayNextPhoneNumber).subscribe((display) => (this.displayPhoneNumber = display));
        this.siteSettingsService
            .getBooleanSetting(SiteSettingType.ContactRequestDefaultDisabled)
            .subscribe((contactRequestDefaultDisabled) => (this.contactRequestDefaultDisabled = contactRequestDefaultDisabled));

        this.subscriptions.push(this.topbarService.currentIsLoggedIn.subscribe((x) => (this.isAuthenticated = x)));

        this.subscriptions.push(this.topbarService.currentUserDisplayName.subscribe((x) => (this.userDisplayName = x)));

        this.subscriptions.push(this.topbarService.currentNumInBasket.subscribe((x) => (this.numInBasket = x)));

        if (this.selfcaremodeoverride !== undefined) {
            this.selfcaremode = this.selfcaremodeoverride;
        }

        this.subscriptions.push(
            this.chatService.getIsChatOpen().subscribe(
                (data) => (this.ischatopen = data),
                (err) => {
                    console.log('getIsChatOpen failed!');
                }
            )
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    toggleLeftMenu(): void {
        this.showLeftMenu = !this.showLeftMenu;
    }

    toggleShowProducts() {
        this.showProducts = !this.showProducts;
        this.showAboutNext = false;
    }

    toggleAboutNext() {
        this.showAboutNext = !this.showAboutNext;
        this.showProducts = false;
    }

    goToSummary() {
        this.goToSummaryLoading = true;
        if (this.numInBasket != null && this.numInBasket > 0) {
            this.router.navigate([this.navigationService.getUrl('summary')]);
        } else {
            this.router.navigate([this.navigationService.getUrl('newcustomer')]);
            this.goToSummaryLoading = false;
        }
    }

    openChat() {
        $zopim.livechat.window.show();
    }

    openContactRequestModal() {
        this.topbarService.setCallmeModal(true);
    }
}
